.App {
  text-align: center;
  min-width: 300px;
}
.App-logo {
  height: 40vmin;
}
.bkTransparency {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.bottomPad {
  padding-bottom: 15px;
}
.breadcrumb {
  background-color: inherit;
  justify-content: center;
}
.cell {
  
}
.cellButton {
  background-image: linear-gradient(to bottom right, blue, #2d355c);
}
.centeredOverImg {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: blue;
  max-width: 325px;
}
.clearText {
  text-shadow: 0px 0px 20px #000000, 0px 0px 10px #000000, 0px 0px 5px #000000;
  color: #E6FFE6;
}
.confirmationInfo {
  outline : 2px dashed yellow;
}
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

.giveInfoInput {
  position: relative;
  text-align: center;
}
.imgNotePad{
  max-width: 100%;
}
.input-group{
  box-shadow: 5px 5px 10px 5px #000000;
}
.LRControls{
  max-height: 75px;
  
}
.negativeNum {
  color: #ff7885; 
}
.noBlur{
  text-shadow: none;
}
.selectBtn{
  margin-top: 5px;
  margin-bottom: 5px;
}
.siteName {
  padding-bottom: 17px;
}

.table th, .table td {
    padding: 0.05rem;

}

.title {
  color: orange;
  text-decoration: underline;
  text-shadow: 3px 2px maroon;
}
.topPad {
  padding-top: 53px;
}
.webBody {
  background-color: #080d71;
}